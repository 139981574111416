'use strict';

class UxrEeMulti extends React.Component {
	constructor(props) {
		super(props);
		//this.state = {};
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let reactItems = [];
		for (let i=0; i<this.props.itemsOrdered.length; i++) {
			let eachItem = this.props.itemsOrdered[i];
			let eachValue = this.props.value? this.props.value[eachItem.idInOver]: undefined;
			let Frame = eachItem.frame.type;
			let idInHierarchy = this.props.idInHierarchy+ '.'+ eachItem.idInOver;
			reactItems.push(
				<Frame {...eachItem} value={eachValue} onChange={this.props.onChange} idInHierarchy={idInHierarchy} key={idInHierarchy} />
			);
		}
		
		return (
			<div {...this.props.htmlProps}>
				{reactItems}
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*
	componentDidMount() {
	}
	
	/*---------------------------------------------------------------------*
	componentWillUnmount() {
	}
	
	/*---------------------------------------------------------------------*/
} //class
